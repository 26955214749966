export const SharedLocale = {
  en: {
    out_of_n_work_orders: 'No Work Orders | For {count} Work Order | For {count} Work Orders',
    you: 'You',
    order_count: '{count} Order | {count} Order | {count} Orders',
    supplier_details: {
      edit: 'Edit',
      detail_head: 'Supplier Details',
      website: 'Supplier Website',
      default_cost: 'Default Cost Account',
      default_terms: 'Payment Terms',
      type: 'Type',
      contacts: 'Contacts',
      phone: 'Phone',
      fax: 'Fax',
      primary: 'Primary',
      primary_contact: 'Primary Contact',
      address: 'Address',
      services: 'Services'
    },
    clone_message: {
      clone_btn: 'Clone instead!',
      heading: 'Clone Design',
      description: '#%{id}-%{title}',
      confirm: 'The cloned design will be attached to this decoration line item. Are you sure you want to clone this design?'
    },
    publish: 'Publish',
    last_updated: 'Last Updated',
    unpublish: 'Unpublish',
    progress_percentage: '{percent} %',
    order_in_hands_date: 'Order In-Hands Date:',
    mark_featured: 'Mark Featured',
    remove_featured: 'Remove Featured',
    default_base_error_message: "Form can't be submitted due to some errors.",
    default_loading_message: 'Loading. Please Wait',
    default_no_data: '--',
    default_not_applicable: 'N/A',
    default_calendar_range_label: 'to',
    event: {
      title: 'Events',
      all_day: 'All Day',
      events_count: 'No Events Scheduled. | | {count} Events Scheduled.',
      past_events: 'Past Events',
      upcoming_events: 'Upcoming Events'
    },
    work_order: {
      quick_show: {
        assignee: 'Assignee',
        archived: 'Archived',
        not_assigned_to_anyone_yet: 'Not assigned to anyone yet',
        creator: 'Creator',
        accept_by: 'Accept By',
        due_date: 'Due By',
        parent_work_order: 'Parent Work Order',
        total_time_logged: 'Estimated Time',
        time_spent: 'Spent Time',
        more_followers: 'more followers...',
        account: 'Account',
        estimated: 'Estimated',
        contact: 'Contact',
        followers: 'Followers',
        more_followers: 'more followers...',
        view_completed: 'View Details',
        sub_wo_count: '| 1 Sub Work Order | {count} Sub Work Orders'
      }
    },
    accounts: {
      account: 'Account Number',
      potential_rating: 'Potential Rating',
      target: 'Target',
      non_target: 'Non Target',
      last_ordered_at: 'Last Ordered At',
      current_year_spend: 'Current Year Spend'
    },
    contacts : {
      destroy: {
        default_failed: 'Contact cannot be deleted',
        failed_due_to_associations: 'Cannot Delete Contact as it has active associations in the system',
        success: 'Successfully deleted Contact'
      }
    },
    orders:{
      contact_form: {
        save: 'Save',
        update: 'Update',
        new_address_modal_heading: 'New Address',
        update_address_modal_heading: 'Update Address',
        contact_name: 'Contact Name',
        email_id: 'Email ID',
        suite: 'Suite',
        address1: "Address 1",
        address2: "Address 2",
        name: "Name",
        company_name: "Company Name",
        street: 'Street',
        phone: 'Phone',
        fax: 'Fax',
        zipcode: 'Zipcode',
        primary: 'Save as primary contact',
        city: 'City',
        state: 'State',
        country: 'Country',
        save_as_primary: 'Save as primary contact',
        is_billing: 'Is Billing',
        is_shipping: 'Is Shipping'
      },
      order_messages: {
        messages_count: '| 1 Message | {count} Messages'
      },
    },
    followers: {
      title: 'Manage Followers',
      followers_count: 'No Followers yet. | {count} Follower | {count} Followers',
    },
    followable: {
      follow_button: 'Follow',
      unfollow_button: 'Unfollow'
    },
    inline_form: {
      copy: 'Copy',
      edit: 'Edit',
      save: 'Save',
      update: 'Update',
      cancel: 'Cancel'
    },
    note_form: {
      save: 'Add Note',
      update: 'Update',
      cancel: 'Cancel'
    },
    notes: {
      notes_count: 'Notes | {count} Note | {count} Notes',
      delete_button: {
        confirm_button_text: 'Delete',
        remove_note: 'Delete',
        delete_note: 'Delete Note',
        delete_confirm_msg: 'Are you sure you want to delete this Note?',
        delete_description: '#{id} - {name}',
      }
    },
    designs: {
      create_eproof_wo: {
        save: 'Save',
        cancel: 'Cancel',
        title_helper_message: 'Please include Customer, Description of item, PO number in the Title',
        estimate_helper_message: 'Enter time in HH:MM format. ex: 1:30 (for 1 hour 30 minutes) and :15 (for 15 minutes)',
      },
    },
    web_notifications: {
      title: 'Notifications',
      mark_all_read: 'Mark all as read',
      mark_read: 'Mark read',
      no_notifications: 'No Notifications',
      view_all: 'View All',
      view_all_notification: 'View All Notifications •••',
      check_updates: 'Check Updates',
      old_notifications_hint: 'Notifications older than 30 days will be deleted automatically.',
      clear_all:'Clear All',
      filterTypes: [
        { id: ' ', text: 'All Updates' },
        { id: 'Task', text: 'Tasks' },
        { id: 'Workorders::SubWorkOrder', text: 'Sub-Work Orders' },
        // TODO_ORDER
        // { id: 'Opportunity', text: 'Opportunities' },
        { id: 'WorkOrder', text: 'Work Orders' },
        { id: 'Activity', text: 'Activities' },
        { id: 'Event', text: 'Events' }
      ]
    },
    paginationControls: {
      nextPage: 'Next >',
      lastPage: 'Last >>',
      morePages: '...',
      firstPage: '<< First',
      previousPage: '< Previous'
    },
    accountQuickAddModal: {
      potential_rating: 'Potential Rating',
      continue_editing: 'Continue Editing',
      title: 'Add new Account',
      submit: 'Save Account',
      cancel: 'Cancel',
      target_account_information: 'Target Account Information',
      target_account_information_helper_text: 'Add following details to indentify if the account is a target',
      name_label: 'Account Name',
      email_label: 'Contact Email',
      zipcode: 'ZipCode',
      company_size_label: 'Number Of Employees',
      funding_label: 'Funding Raised',
      special_event: 'Special Event',
      large_customer_affiliate: 'Large Customer Affiliate',
      save_text: 'Save',
      link_account: 'Link Account',
      contact_email_id: 'Contact Email ID',
      targetConfirmationHeading: 'Account will be created as a target',
      nonTargetConfirmationHeading: 'Account will be created as a non-target'
    },
    departmentQuickAddModal: {
      title: 'Add new Department',
      submit: 'Save Department',
      cancel: 'Cancel',
      name_label: 'Name'
    },
    eventModal: {
      title: 'Add Event',
      addTitle: 'Add Title',
      findTimeActionLabel: 'Find a time',
      addEventActionLabel: 'Add Event',
      organizer: 'Organizer',
      invitee_email_info: 'An email invite will be sent to all selected members.',
    },
    autoComplete:{
      default_hint: 'Start typing to see suggestion (min 3 characters)',
      invitee_placeholder:'Invite guests by email or search by name'
    },
    scheduleModal:{
      title:'Add Schedule',
      addTitle:'Add Title',
      addScheduleActionLabel:'Add Schedule',
      updateScheduleActionLabel: 'Update Schedule'
    },
    filter_types: {
      id: 'ID',
      name: 'Filter Label',
      sub_filter_count: 'Sub Filters',
      number_of_products: 'Number of Items',
      date_created: 'Date Added',
      add_products: {
        success: 'Products mapping has been successfully added',
        not_found: 'No results found for searched keyword.',
        match_records_count: '{count} Item Selected. | {count} Item Selected. | {count} Items Selected.',
        select_all: 'Select All',
        unselect_all: 'Deselect All',
        matching_sizes: '{count} matching Filter Types.',
        review_products_count: 'Review Selected | Review Selected ({count}) | Review Selected ({count})',
        search_text: 'Search for Items in Sage Database by keyword. For e.g. T-Shirt or Tee'
      },
      update: {
        success: 'Filter Type updated successfully - <b><a href={url}>{name}.</a></b>'
      },
      delete: {
        success: 'Filter Type deleted successfully.'
      },
      filter_detail: {
        sub_filter_count: '0 Sub filters | {count} Sub filter | {count} Sub filters',
        products_count: '0 total listed item | {count} total listed item | {count} total listed items',
        success: 'Filter value created successfully - <b><a href={url}>{name}</a></b>',
        created_at: 'Created '
      },
      confirm: 'Delete Permanently',
      delete_heading: 'Delete Sub Filter',
      delete_confirm_msg: 'Are you sure you want to delete this Sub filter?',
      delete_description: '#{id} - {name}',
    },
    filter_value_listing: {
      name: 'Item Name',
      sage_pr_code: 'Item Code',
      date_added: 'Date Added',
      no_records: 'There are no items',
      edit: 'Edit',
      manage_products: 'Manage Items',
      no_search_results: 'No Search Results.',
      import_sage_products: 'Import Items from Sage',
      remove_button: 'Remove Selected',
      success_removed: 'Item mapping has been successfully removed',
      remove_modal_heading: 'Remove Item',
      remove_modal_description: '| Are you sure you want to remove selected item? | Are you sure you want to remove selected items?',
      remove_confirm: 'Yes, Remove',
      delete_description: '{name}',
      remove_product_msg: 'Are you sure you want to remove this item?',
      delete_description: '#{id} - {name}',
      multiple_delete_description: '| {count} selected item will be removed. | {count} selected items will be removed.',

    },
    filter_type_detail: {
      id: 'ID',
      name: 'Name',
      listed_items: 'Listed Items',
      date_added: 'Date Added',
      sub_filter_label: 'Sub Filter Label',
    },
    create_sub_filter_modal: {
      help_text: 'Suggested under 40 characters. You will be able to add items in next step.'
    },
    task_row: {
      archive: 'Archive',
      cancel: 'Cancel',
      delete: 'Delete',
      edit: 'Edit',
      discard: 'Discard',
      cancel_task_button_text: 'Yes, Cancel',
      back: 'Back',
      delete_permanently: 'Delete Permanently',
      entity_details: '#%{entity_id} - %{entity_title}',
    },
    quick_add_contact: {
      mobile_number: 'Mobile Number',
      account_target: 'Account is a Target',
      targetConfirmationHeading: 'This lead will be converted to a prospect since the linked account is a target',
      account_not_target: 'Account is not a Target',
      modal_title: 'Add New {title}',
      no_results_found: 'No Results found',
      helper_message: 'Please enter %{min_characters} or more characters...',
      lead_heading: '{title} Information',
      name_label: 'Name',
      email_label: 'Email',
      phone_label: 'Phone Number',
      account_label: 'Account',
      account_team_label: 'Team',
      cancel: 'Cancel',
      submit: 'Save',
      create_success: 'Contact has been created successfully',
      continue_editing: 'Continue Editing',
      contact_type: 'Contact Type',
      email_reuse_warning: "A contact is associated with this email in the system.\nDo you want to proceed?"
    },
    quick_add_task: {
      title_label: 'Title',
      title_placeholder: 'Write Task Title...',
      assignee_label: 'Assignee',
      due_date_label: 'Due Date',
      followers_label: 'Followers',
      followers_placeholder: 'Add Followers',
      description_placeholder: 'Add Description...',
      category_label: 'Category',
      cancel: 'Cancel',
      submit: 'Add Task',
      attachments: 'Attachments',
      create_success: 'Task has been created successfully'
    },
    history_row: {
      follower: 'follower',
      message: 'message',
      task: 'task',
      design: 'design',
      design_state: 'design',
      demo_needed: 'Client/AM Demo Needed',
      file: 'file',
      attachment: 'attachment',
      vendor_bill_attachment: 'vendor bill',
      text_detail: {
        updated: 'updated',
        added: 'added',
        removed: 'removed',
        title: 'title',
        name: 'name',
        design: 'design',
        description: 'description',
        order_notes: 'order notes',
        order_purpose: 'order purpose',
        estimated_revenue: 'estimated revenue',
        shipping_method: 'shipping method',
        payment_method: 'payment method',
        payment_term: 'payment terms',
        order_type: 'order type',
        destination: 'ship to',
        reference_number: 'reference number',
        shipping_account_number: 'shipping account number'
      },
      values: {
        order_purpose: {
          special_event: 'Special Event',
          marketing_colletral: 'Marketing Colletral',
          corporate_identity: 'Corporate Identity',
          tradeshows: 'Tradeshows',
          sample_purpose: 'Sample',
          webstore_inventory: 'Webstore Inventory',
          kitting_fulfilment_project: 'Kitting & Fulfillment Project',
          international_program: 'International program',
          technology: 'Technology(non product)'
        },
        order_type: {
          new_order: 'New Order',
          re_order: 'Re-Order',
          reprint_order: 'Reprint Order',
          customer_pass_through: 'Customer Pass Through',
          retail_order: 'Retail Order',
          webstore_invoice: 'Webstore Invoice',
          print_on_demand: 'Print On Demand',
          sample_order: 'Sample',
          on_site_event: 'On Site Event',
          sponsored: 'SI Sponsored Tradeshow'
        },
        shipping_method: {
          priority_overnight: 'FedEx Priority Overnight',
          fedex_2_day: 'Fedex 2 Day',
          standard_overnight: 'FedEx Standard Overnight',
          first_overnight: 'FedEx First Overnight',
          express_saver: 'FedEx Express Saver',
          fedex_1_day_freight: 'Fedex 1 day freight',
          fedex_2_day_freight: 'Fedex 2 day freight',
          fedex_3_day_freight: 'Fedex 3 day freight',
          international_priority: 'FedEx International Priority',
          international_economy: 'FedEx International Economy',
          international_first: 'FedEx International First',
          international_priority_freight: 'FedEx International Priority Freight',
          international_economy_freight: 'FedEx International Economy Freight',
          ground_home_delivery: 'FedEx Ground Home Delivery',
          fedex_ground: 'Fedex Ground',
          international_ground: 'FedEx International Ground',
          next_day_air: 'UPS Next Day Air',
          second_day_air: 'UPS Second Day Air',
          ups_ground: 'UPS Ground',
          worldwide_express: 'UPS Worldwide Express',
          worldwide_expedited: 'UPS Worldwide Expedited',
          standard: 'UPS Standard',
          three_day_select: 'UPS Three Day Select',
          next_day_air_saver: 'UPS Next Day Air Saver',
          next_day_air_early_am: 'UPS Next Day Air Early A.M.',
          worldwide_express_plus: 'UPS Worldwide Express Plus',
          second_day_air_am: 'UPS Second Day Air A.M.',
          saver: 'UPS Saver',
          today_standard: 'UPS Today Standard',
          today_dedicated_courier: 'UPS Today Dedicated Courier',
          today_intercity: 'UPS Today Intercity',
          today_express: 'UPS Today Express',
          today_express_saver: 'UPS Today Express Saver',
          first_class: 'USPS FIRST CLASS',
          priority: 'USPS PRIORITY',
          express: 'USPS EXPRESS',
          bpm: 'USPS BPM',
          parcel: 'USPS PARCEL',
          media: 'USPS MEDIA',
          library: 'USPS LIBRARY',
          usps_all: 'USPS All',
          pick_up: 'Customer Pick-Up',
          fedex_2_day_am: 'FedEx 2 Day AM',
          fedex_saturday_delivery: 'FedEx Saturday Delivery',
          free_freight: 'Free Freight',
          truck: 'Truck',
          fedex_smart_post: 'FedEx Smart Post',
          first_class_package_service: 'USPS First Class Package Service',
          express_mail_international: 'USPS Express Mail International',
          priority_mail_international: 'USPS Priority Mail International',
          first_class_package_international: 'USPS First Class Package International',
          worldwide_saver: 'UPS Worldwide Saver',
          next_day_air_am: 'UPS Next Day Air A.M.',
        },
      }
    },
    task_modal: {
      title: '%{id} - %{task_category}',
      title_placeholder: 'Enter Title',
      description_placeholder: 'Enter Description',
      cancelled_text: 'Task was marked as canceled on ',
      mark_as_complete: 'Mark as Complete',
      task_details: '#%{id} - %{task_details}',
      completed: 'Mark as Incomplete',
      tabs: {
        details: 'Details',
        history: 'History',
        timelogs: 'Time Logs',
        comments: 'Comments'
      },
      details: {
        due_date_label: 'Due Date',
        assignee_label: 'Assignee',
        time_log_label: 'Time Logged',
        followers_label: 'Followers',
        category_label: 'Category',
        referenced_label: 'Referenced In',
        formatted_time: '%{time} hr',
        attachments_label: 'Attachments',
        created_by: 'Task created by',
        last_edited: 'Last Edited: %{edited}'
      }
    },
    task_row: {
      archive: 'Archive',
      cancel: 'Cancel',
      delete: 'Delete',
      edit: 'Edit',
      discard: 'Discard',
      cancel_task_button_text: 'Yes, Cancel',
      back: 'Back',
      delete_permanently: 'Delete Permanently',
      entity_details: '#%{entity_id} - %{entity_title}',
    },
    vue_task_list: {
      archive: 'Archive',
      cancel: 'Cancel',
      delete: 'Delete',
      edit: 'Edit',
      discard: 'Discard',
      cancel_task_button_text: 'Yes, Cancel',
      back: 'Back',
      delete_permanently: 'Delete',
      entity_details: '#%{entity_id} - %{entity_title}',
    },
    task_time_log_modal: {
      title: '%{title}',
      duration_placeholder: 'Time (6:30)',
      notes_placeholder: 'Add notes to this time entry',
    },
    comments_list: {
      delete_permanently: 'Delete Permanently',
      edit: 'Edit',
      cancel: 'Cancel',
      delete: 'Delete',
      details: '#%{id} - %{body}',
      delete_heading: 'Delete Comment',
      confirmation_message: 'Are you sure you want to delete this comment?',
    },
    timeDurationInWordsFormat: {
      hours: ' | {count} hr | {count} hrs',
      mins: ' | {count} min | {count} mins'
    },
    infotext: {
      line_exact_quantity: 'Specify Customer upcharge if Exact Quantity is required',
      opportunity_close_by: 'Date expected to have estimate approved',
      estimated_revenue: 'Expected revenue on the approved estimate',
      followers: 'Follower will get same notifications when any changes are made in  the Opportunity',
      purchaser: "Select Social Imprint's purchaser",
      department: "Select Client's Department (if known)",
      billing_address: 'Where do you want to send the Bill to',
      fees_requested_completed_in_hands: 'For Shipping Fee and Webstore Line items, enter Ship date/Completion Date in "Completion Date/Ship Date'
    }
  }
};
